//import Components
import PropTypes from "prop-types";
import Social from "./Social";

SocialList.propTypes = {

}

SocialList.defaultProps = {

}


//----------------[Social List]---------------------//
//used in footer

export default function SocialList ({className, cols, socials, ...props}) {
    return (
        <>
            <div className={`${className}`}>
                {socials.map((item, i) =>  {
                    return (
                        <Social cols={cols} item={item} key={`s-${i}`}/>
                    )
                })}
            </div>
        </>
    )
}