//import Components
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

Social.propTypes = {

}

Social.defaultProps = {

}

//----------------[Social Item]---------------------//
//used in footer

export default function Social ({item, cols, ...props}) {

    //console.log(item.customIcon);

    return (
        <>
            <Link className={`social_iconContainer link--hover ${cols}`} to={item.link}>
                {item.customIcon ?
                    <img className={`social-icon link`} src={`/media/images${item.customIcon}`} alt={`${item.customIcon}'s Logo`}/>
                    :
                    <FontAwesomeIcon className={`social-icon link`} icon={`fa-brands fa-${item.name.toLowerCase()}`}/>
                }
            </Link>
        </>
    )
}