//import Components
import Slider from "react-slick";
import {SampleNextArrow, SamplePrevArrow} from "./arrows";

const config = {
    other: {
        breakpoint: {max: Infinity, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

//----------------[Hero]---------------------//

export default function Hero ({items, className, ...props}) {
    const carouselSettings = {
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: items.length > 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    }

    className = className + " carousel";
    return (
        <>
            {props.children}
            <Slider className={className} {...carouselSettings} {...props}>
                {items.map((item, i) => {
                    if (item.background !== "") {
                        return (
                            <div className={`carousel_item`} key={`f-${i}`}>
                                <img className={`carousel-img`} src={`/media/images${item.background}`} alt={`showcase + ${i}`}/>
                                <div className={`carousel_text`}>
                                    <h2 className={`carousel-heading`}>{item.heading}</h2>
                                    <div className={`carousel-content`}>{item.content}</div>
                                </div>
                            </div>
                        )
                    } else return null;
                })
                }
            </Slider>
        </>
    )
}