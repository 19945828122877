import {useAppState} from "../context/global";
import {useEffect} from "react";

//----------------[Global Functions]---------------------//
//exporting functions for other pages to include in their render

/*function to set a timeout on event listeners (needed to make it not lag & use a lot of resources on large functions)
or functions that run a lot of times in short amount of time.*/
export function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}


//function for scrolling to top of pages
export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
    });
}