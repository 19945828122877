//import Components
import Carousel from "react-multi-carousel";
import Cover from "../Cover/Cover";
import Slider from "react-slick";
import {SampleNextArrow, SamplePrevArrow} from "./arrows";

CategoryList.propTypes = {

}

CategoryList.defaultProps = {

}
//----------------[This is code for global variables across all pages]---------------------//
//this page except the carouselResponsive values should not be edited

export default function CategoryList ({items, label, className, infinateScroll= false,...props}) {
    const carouselSettings = {
        slidesToScroll: 2, //sets fallback default value for how many slides to show
        infinite: false, //sets fallback default value for if the slides should have infinite scroll
        nextArrow: <SampleNextArrow />,//custom arrows for slides can be found in "..../carousel/arrows.js"
        prevArrow: <SamplePrevArrow />,//custom arrows for slides can be found in "..../carousel/arrows.js"
        lazyLoad: true, //do not remove this setting, this helps page load quicker by only loading in elements when
        // in view
    }

    const carouselResponsive = [
        {
            breakpoint: 375, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 1, //this value should always be same as slides to show
                slidesToShow: 1 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        },
        {
            breakpoint: 520, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 2, //this value should always be same as slides to show
                slidesToShow: 2 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        },
        {
            breakpoint: 750, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 2, //this value should always be same as slides to show
                slidesToShow: 2 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        },
        {
            breakpoint: 1300, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 3, //this value should always be same as slides to show
                slidesToShow: 3 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        },
        {
            breakpoint: 999999, //maximum width before this no longer applies
            settings: {
                infinite: items.length > 4, //this value should always be same as slides to show
                slidesToShow: 4 //number of slides to show for this view config (from previous/0 -> breakpoint)
            }
        }

    ]
    if (items.length > 0) {
        return (
            <>
                <div className={`releaseCategory`}>
                    <div className={`release_label`}>
                        <h3 className={`releases-label`}>{label.toUpperCase()}</h3>
                    </div>
                    <Slider className={`release_list ${className}`} responsive={carouselResponsive} {...carouselSettings}>
                        {items.map((item, i) => {
                            return (
                                <Cover item={item} hoverEffect={true} key={`r-${label.charAt(0)}-${i}`}/>
                            )
                        })}
                    </Slider>
                </div>
            </>
        )
    } else return null;
}