//import Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from "prop-types";

Item.propTypes = {
    item: PropTypes.object
}

Item.defaultProps = {

}

//----------------[Platform Item]---------------------//
export default function Item ({item, platform, ...props}) {
    return (
        <>
            <Link to={item.link} className={`platformDisp-item`}  {...props}>
                <p className={`platformDisp-name h3`}>{platform.charAt(0).toUpperCase() + platform.slice(1)}</p>
                <div className={`platformDisp_iconHolder ${platform}`}>
                    {item.customIcon ?
                        <img src={`/media/images/${item.customIcon}`} alt=""/>
                        :
                        <FontAwesomeIcon className={`platformDisp-icon ${platform} link`} icon={`fa-brands fa-${platform}`} />
                    }
                </div>
                <p className={``}>PGN Music</p>
            </Link>
        </>
    )
}