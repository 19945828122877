//import Components
import react, {useEffect, useState, lazy, Suspense } from "react"
import {Routes, Route} from "react-router-dom";
import {useAppState} from "./context/global";
import Layout from "./Layouts/MainLayout";
import Home from "./pages/home";
import {Helmet} from "react-helmet";
import ReactLoading from 'react-loading';
import PropTypes from "prop-types";
import {scrollToTop} from "./components/functions";
const About = lazy(() => import("./pages/about"));
const Releases = lazy(() => import("./pages/releases"));
const Item = lazy(() => import("./pages/releases/item"));
const PageNotFound = lazy(() => import('./pages/errors/404'));
const BadRequest = lazy(() => import("./pages/errors/400"));
App.propTypes = {

}

App.defaultProps = {

}

export function LoadingComponent() {
    return (
        <div className="App" style={{background: "#000000", minHeight: "100vh"}}>
            <div className="page_loading" style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                <ReactLoading delay={500} className={`page-loading`} type={"spinningBubbles"} color={"#efefef"} height={'10%'} width={'10%'}/>
            </div>
        </div>
    )
}


//--------------------------[!Note]----------------------------------//
//this page should never be needed to be updated unless adding pages (ask Zettrex)
//if you want to add content like SEO or meta content add in HELMET(works like <head></head>) element just like good old HTML


//function for sorting through the list of releases
function sortReleases(data) {/*function to sort releases by release date*/

    data.releases.sort((a,b) => {
        return new Date(a.releaseDate) > new Date(b.releaseDate);
    });
    return data;
}

/*React app component*/
export default function App ({...props}) {
    const [appState, setAppState] = useAppState();
    const [error400, setError400] = useState(false);

    //This gets the data for the webpage and adds them to a variable to be used to fill the page
    useEffect(() => {
        //console.log(fetchData());
        function fetchData() {
            fetch("/data.json")
                .then(response => {
                    //console.log(response);
                    return response.json();
                })
                .then(fetchData => {
                    //console.log(fetchData);
                    const data = sortReleases(fetchData);
                    setAppState({
                        ...appState,
                        data: {
                            ...data,
                        },
                        keywords: `PGN Music, DCMA free, Copyright free, ${data.categories.map(category => category.label)}, releases, paradox gaming network`
                    });
                    setError400(false);
                    return data;
                })
                .catch(error => {
                    console.error("Error getting content, slacker has done something bad.", error);
                    setError400(true);
                })
        }
        fetchData();
        //setTimeout(fetchData, 30000);
    }, []);

    /*Makes sure we have data before we load the page*/
    if (appState.data) {
        return (
            <>
                <div className="App">
                    <Helmet>
                        <meta name={`description`} content={`PGN Music, Your place for DMCA/Copyright free music.`}/>
                        <meta name={`keywords`} content={appState.keywords} key={`base-keywords`}/>
                    </Helmet>
                    <Layout>
                        <Suspense fallback={<LoadingComponent/>}>
                            <Routes>
                                <Route path="/" exact element={<Home/>}/>
                                <Route path="/about" exact element={<About/>}/>
                                <Route path="/releases" exact element={<Releases/>}/>
                                {/*<Route path="/admin" exact element={<Admin/>}/>*/}{/*This page is not active untill I
                             do the final database*/}
                                <Route path="/releases/:id" element={<Item/>}/>
                                <Route path="/400" element={<BadRequest/>}/>
                                <Route path="*" element={<PageNotFound/>} />
                            </Routes>
                        </Suspense>
                    </Layout>
                </div>
            </>
        )
    } else if (error400) {
        //If we hit an error getting data from Database, we will display the BadRequest page
        return <BadRequest/>;
    } else {
        /*If we still are loading in data, we will display a loading component*/
        return <LoadingComponent/>;
    }

}