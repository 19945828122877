//import Components
import { useLocation } from "react-router-dom"
import Nav from "../components/Navigation/Nav";
import Footer from "../components/Footer/Footer";
import {useAppState} from "../context/global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useRef} from "react";
import {debounce} from "../components/functions";
import {scrollToTop} from "../components/functions";
import $ from "jquery";

function useFixToTopBtn(elementRef) {
    useEffect(() => {
        const btn = elementRef.current.toTopBtn;
        const footer = elementRef.current.footer;
        function checkScroll() {
            if (((window.scrollY + window.innerHeight) > (window.innerHeight * 1.20)) && ((window.scrollY + window.innerHeight) < ($(document).height() - (footer.offsetHeight -90)))) {
                btn.style.bottom = "-400px"
                btn.className = "toTop-btn--fixed";
                btn.style.bottom = "";
            } else {
                btn.className = "toTop-btn";
                btn.style.bottom = `${footer.offsetHeight}px`;
                btn.style.transform = "";
            }

        }

        window.addEventListener("scroll", debounce(checkScroll, 0));
        // Call handler right away so state gets updated with initial window size
        checkScroll();
        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", debounce(checkScroll, 0));
    }, [])

}




//----------------[App - Layout]---------------------//

export default function Layout({...props}) {
    const [appState, setAppState] = useAppState();
    const data = appState.data;

    const router = useLocation();
    //useCheckRedirect(router);
    let page = router.pathname.split(/[/ ]+/).pop();
    if (!page) {
        page = "home";
    }

    //scroll to top on page redirect
    useEffect(() => {
        scrollToTop();
    }, [router])

    //scroll to top of page on page change
    const elementRef = useRef({});
    useFixToTopBtn(elementRef);

    //This ads navigation and footers... (items that should be on all pages)
    return (
        <>
            <Nav/>
            <main id={page} className={`page`}>
                <div id={`pageContent`} className={`page_container`}>
                    {props.children}
                </div>
            </main>
            <div className={`toTop-btn link--hover`} onClick={scrollToTop} ref={el => elementRef.current.toTopBtn = el}>
                <FontAwesomeIcon className={`toTop-icon`} icon="fa-solid fa-angles-up"/>
            </div>
            <Footer forwardRef={el => elementRef.current.footer = el}/>
        </>
    )
}