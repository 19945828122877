//import Next Components
import React from 'react';
import PropTypes from "prop-types";
import FCarousel from "../../components/Carousel/Hero";
import PlatformList from "../../components/Platforms/PlatformList";
import {useAppState} from "../../context/global";
import {Helmet} from "react-helmet";
import CategoryList from "../../components/Carousel/Releases";
import {scrollToTop} from "../../components/functions";


Home.propTypes = {
    items: PropTypes.object
}

Home.defaultProps = {

}

//----------------[Page - Homepage]---------------------//

export default function Home({...props}) {
    const [appState, setAppState] = useAppState();
    const data = appState.data;


    //content to render to page
    return (
        <>
            <Helmet>
                <title key="title">PGN Music | Home</title>
            </Helmet>
            <section className={`home_carousel page_section--fullWidth hero--fade`}>
                <FCarousel items={data.hero} className={`home_heroCarousel heroCarousel`}/>
            </section>
            <section className={`home_releases page_section`}>
                <CategoryList className={`home_latest`} items={data.releases} label={"Latest Releases"}/>
            </section>
            <section className={`home_platforms page_section`}>
                <PlatformList items={data.platforms}/>
            </section>
        </>
    )
}
