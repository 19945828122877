//Importing Components
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useEffect, useState} from "react";
import {debounce} from "../functions";
import {useAppState} from "../../context/global";

//import media
import PropTypes from "prop-types";

Nav.propTypes = {

}

Nav.defaultProps = {

}

//importing Media
const navLogo = "../../media/images/icons/Nav Logo.png"

//variable declaration
const navLogoSize = 50;
const delayTime = 50;

function toggleHamburger(state, setState) {
    setState(!state)
}

function useCheckScroll(pageScrolled, setPageScrolled) {
    useEffect(() => {
        function checkScroll() {
            //console.log(window.scrollY);
            if (window.scrollY > 50 && !pageScrolled) {
                setPageScrolled(true);
            } else {
                setPageScrolled(false);
            }
        }

        window.addEventListener("scroll", debounce(checkScroll, delayTime));
        // Call handler right away so state gets updated with initial window size
        checkScroll();
        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", debounce(checkScroll, delayTime));
    }, [pageScrolled, setPageScrolled])
}


//----------------[Navigation Components]---------------------//

export default function Nav({showLogin, ...props}) {
    const [pageScrolled, setPageScrolled] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    //useCheckScroll(pageScrolled, setPageScrolled);
    const [appState, setAppState] = useAppState();
    //console.log(appState);

    function signOut() {
        localStorage.removeItem("user");
        setAppState({...appState, user: null});
    }



    return (
        <>
            <nav className={`nav${pageScrolled ? " scrolled" : ""}`}>
                <div className={`nav_container`}>
                    <Link className={`jumpToContent`} to={`#pageContent`}>Jump to content</Link>
                    {(appState.user) && (
                        <div className={`nav_admin`}>
                            <button className={`nav_signOut`} onClick={signOut}>Sign out</button>
                        </div>
                    )}
                    <div className={showMenu ? "nav_links--show" : "nav_links"} onClick={() => showMenu && setShowMenu(!setShowMenu)}>
                        <NavLink className={`nav-item navLink`} to={'/'}>HOME</NavLink>
                        <NavLink className={`nav-item navLink`} to={'/releases'}>RELEASES</NavLink>
                        <NavLink className={`nav-item navLink`} to={'/about'}>ABOUT</NavLink>
                        {(appState.user) && (
                            <NavLink className={`nav-item navLink`} to={'/admin'}>ADMIN</NavLink>
                        )}
                    </div>
                    <div className={`nav_navbar`}>
                        <div className={`nav_left`}>
                            <Link className={`nav_logoHolder navLink`} to={'/'}>
                                <img className={`nav-logo`} src={navLogo} alt={`something`}/>
                            </Link>
                        </div>
                        <div className={`nav_center`}>

                        </div>
                        <div className={`nav_right`}>
                            <a className={`nav-btn navLink`} href={`https://open.spotify.com/artist/4qZWTa2bhoPr2AOsANBazT?si=DYkmx9uYQbKyvSU1T1969g`} target={"_blank"} rel={"noopener noreferrer"}>
                                <FontAwesomeIcon className={`nav-findMe spotify`} icon="fa-brands fa-spotify" />
                            </a>
                            <a className={`nav-btn navLink`} href={`https://www.youtube.com/PGNMusic`} target={"_blank"} rel={"noopener noreferrer"}>
                                <FontAwesomeIcon className={`nav-findMe youtube`} icon="fa-brands fa-youtube" />
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className={`nav_mobile`}>
                <div className={`mobile_navbar`}>
                    <NavLink className={`mobile-item`} to={"/"}>
                        <FontAwesomeIcon className={`mobile-icon`} icon="fa-solid fa-house" />
                        <div className={`mobile-label`}>Home</div>
                    </NavLink>
                    <NavLink className={`mobile-item`} to={"/releases"}>
                        <FontAwesomeIcon className={`mobile-icon`} icon="fa-solid fa-compact-disc" />
                        <div className={`mobile-label`}>Releases</div>
                    </NavLink>
                    <NavLink className={`mobile-item`} to={"/about"}>
                        <FontAwesomeIcon className={`mobile-icon`} icon="fa-solid fa-address-card" />
                        <div className={`mobile-label`}>About</div>
                    </NavLink>
                    <button className={`nav_hamburger mobile-item`} onClick={() => toggleHamburger(showMenu, setShowMenu)}>
                        <FontAwesomeIcon icon="fa-solid fa-bars" />
                        <div className={`mobile-label`}>Menu</div>
                    </button>
                </div>
            </nav>
        </>

    )
}