//import Components
import {createContext, useContext, useState, useEffect} from "react";

//----------------[This is code for global variables across all pages]---------------------//

const defaultData = {

}

const AppContext = createContext(undefined);


export function AppStateProvider ({...props}) {
    const [appState, setAppState] = useState(defaultData);
    return (
        <>
            <AppContext.Provider value={[appState, setAppState]}>
                {props.children}
            </AppContext.Provider>
        </>
    )
}

export function useAppState() {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("useAppState must be within AppStateProvider");
    }
    return context;
}