//import Components
import Platform from "../Platforms/Platform";
import PropTypes, {object} from "prop-types";

List.propTypes = {
    items: PropTypes.arrayOf(object).isRequired
}

List.defaultProps = {

}


//----------------[Platform List]---------------------//
export default function List({items, ...props}) {
    return (
        <>
            <div className={`platformDisp`}>
                {props.children}
                <div className={`platformDisp_list`} {...props}>
                    {items.map((item, i) => {
                        return <Platform item={item} platform={items[i].name.toLowerCase()} key={`p-${i}`}/>
                    })}
                </div>
            </div>
        </>

    )
}