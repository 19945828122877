//import styles
import './styles/styles.scss'

//importing next components
import {AppStateProvider} from "./context/global";
import { BrowserRouter} from "react-router-dom";
import App from "./App";
import reactDOM from "react-dom";

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

library.add(fab, fas);

reactDOM.render(
    <>
        <Helmet>
            <title key="title">PGN Music</title>
        </Helmet>
        <AppStateProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </AppStateProvider>
    </>,
    document.getElementById('root')
)
